import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  props: ['batchDetail'],
  name: 'createPhysicalVerification',
  components: { DatePicker },
  watch: {
    batchCurrentPage() {
      this.getCreateBatchDetailsById();
    },
    batchPerPage() {
      this.batchCurrentPage = 1;
      this.getCreateBatchDetailsById();
    },
  },
  data() {
    return {
      defaultValue: {
        text: null,
        value: null
      },
      showValueSetModal: false,
      responseMsg: '',
      showAlert: false,
      isSuccess: false,
      loader: false,
      batchNo: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: null,
      location: {
        text: null,
        value: null
      },
      batchStatus: null,
      type: {
        text: null,
        value: null
      },
      assestStatus: {
        text: null,
        value: null
      },
      recoStatus: {
        text: null,
        value: null
      },
      assetNo: "",
      manufacturer: "",
      serialNo: "",
      modelNo: "",
      poNumber: "",
      description: "",
      startDate: "",
      endDate: "",
      valueFrom: null,
      valueTo: null,
      showExcelUploadModal: false,      
      createPhysicalVerificationData: [],
      createPhysicalVerificationFields: [
        {
          key: 'doc_num',
          label: 'Tag Number'
        },
        {
          key: 'new_tag_number',
          label: 'New Tag Number'
        },
        {
          key: 'reco_status_meaning',
          label: 'Reco Status'
        },
        {
          key: 'asset_status_meaning',
          label: 'Asset Status'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'description'
        },
        {
          key: 'actual_cost',
          label: 'Value'
        },
        {
          key: 'manufacturer_name',
          label: 'Manufacturer'
        }, 
        {
          key: 'model_no',
          label: 'Model Number'
        },  
        {
          key: 'serial_no',
          label: 'Serial Number'
        },
        {
          key: 'taged_status_meaning',
          label: 'Tag Status'
        }, 
        {
          key: 'print_status_meaning',
          label: 'Print Status'
        },  
        {
          key: 'asset_condition',
          label: 'Condition'
        },
      ],
      totalRows: null,
      transferHdrId: null,
      batchPerPage: commonHelper.perPageRecord,
      batchPageOptions: commonHelper.getPageOption(),
      batchCurrentPage: 1,
    };
  },
  validations: {},
  mounted() {
    this.fillRecordForBatchDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  methods: {
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
      
    },
    fillRecordForBatchDetails() {
      this.batchNo = this.batchDetail.doc_num;
      this.legalEntity.text = this.batchDetail.le_name;
      this.legalEntity.value = this.batchDetail.le_id;
      this.batchStatus = this.batchDetail.batch_status_meaning;
      this.bookType = this.batchDetail.book_type_code;
      this.location.text = this.batchDetail.location_name;
      this.location.value = this.batchDetail.location_id;
      this.pvHdrId = this.batchDetail.pv_hdr_id;
    },
    getCreateBatchDetailsById() {
      const payload = {
        pvHdrId: this.pvHdrId,
        _page: this.batchCurrentPage - 1,
        _limit: this.batchPerPage,
        searchParams: {
        book_type_code: this.bookType,
        le_id: this.legalEntity.value,
        location_id: this.location.value,
        asset_type_vset: this.type.text,
        asset_status: this.assestStatus.text,
        serial_no: this.serialNo,
        model_no: this.modelNo,
        po_number: this.poNumber,
        description: this.description,
        purchase_date_from: this.startDate,
        purchase_date_to: this.endDate,
        actual_cost_from: this.valueFrom,
        actual_cost_to: this.valueTo,
        doc_num: this.assetNo,
        manufacturer_name: this.manufacturer,
        }
        
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getCreateBatchDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.createPhysicalVerificationData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => (this.loader = false));
      },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.startDate
      );
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.bankListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_TYPE) {
        this.type = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_STATUS) {
        this.assestStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_RECO_STATUS) {
        this.recoStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.type.value) {
        this.type = this.defaultValue;
      } else if (vsetCode === this.assestStatus.value) {
        this.assestStatus = this.defaultValue;
      }else if (vsetCode === this.recoStatus.value) {
        this.recoStatus = this.defaultValue;
      }
    },
    clear() {
      this.type = this.defaultValue;
      this.assestStatus = this.defaultValue;
      this.recoStatus = this.defaultValue;
      this.assetNo = null;
      this.manufacturer = null;
      this.serialNo = null;
      this.modelNo = null;
      this.poNumber = null;
      this.description = null;
      this.startDate = null;
      this.endDate = null;
      this.valueFrom = null;
      this.valueTo = null;
    }
  }
};
